@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,500,300,700);

.bg-footer {
    /* background-color: #0f6e6a; */
    background-color: #004f44;
}

* {
    font-family: Open Sans;
}


.footer-distributed {
    background-color: transparent;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.12);
    box-sizing: border-box;
    width: 100%;
    text-align: left;
    font: bold 16px sans-serif;
    padding: 55px 50px;
}

.footer-distributed .footer-left,
.footer-distributed .footer-center,
.footer-distributed .footer-right {
    display: inline-block;
    vertical-align: top;
}

/* Footer left */

.footer-distributed .footer-left {
    width: 40%;
}

/* The company logo */

.footer-distributed h3 {
    color: #ffffff;
    font: normal 36px 'Open Sans', cursive;
    margin: 0;
}

.footer-distributed h3 span {
    color: lightseagreen;
}

/* Footer links */

.footer-distributed .footer-links {
    color: #ffffff;
    margin: 20px 0 12px;
    padding: 0;
}

.footer-distributed .footer-links a {
    display: inline-block;
    line-height: 1.8;
    font-weight: 400;
    text-decoration: none;
    color: inherit;
}

.footer-distributed .footer-company-name {
    color: lightseagreen;
    font-size: 14px;
    font-weight: normal;
    margin: 0;
}

/* Footer Center */

.footer-distributed .footer-center {
    width: 35%;
}

.footer-distributed .footer-center .icon-outer {
    background-color: #33383b;
    color: #ffffff;
    width: 38px;
    height: 38px;
    border-radius: 50%;
    text-align: center;
    line-height: 42px;
    vertical-align: middle;
}

.footer-distributed .footer-center .icon {
    font-size: 17px;
    line-height: 38px;
}

.footer-distributed .footer-center p {
    display: inline-block;
    color: #ffffff;
    font-weight: 400;
    vertical-align: middle;
    margin: 0;
}

.footer-distributed .footer-center p span {
    display: block;
    font-weight: normal;
    font-size: 14px;
    line-height: 2;
}

.footer-distributed .footer-center p a {
    color: lightseagreen;
    text-decoration: none;
    ;
}

.footer-distributed .footer-links a:before {
    content: "|";
    font-weight: 300;
    font-size: 20px;
    left: 0;
    color: #fff;
    display: inline-block;
    padding-right: 5px;
}

.footer-distributed .footer-links .link-1:before {
    content: none;
}

/* Footer Right */

.footer-distributed .footer-right {
    width: 20%;
}

.footer-distributed .footer-company-about {
    line-height: 20px;
    color: #92999f;
    font-size: 13px;
    font-weight: normal;
    margin: 0;
}

.footer-distributed .footer-company-about span {
    display: block;
    color: #ffffff;
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 20px;
}

.footer-distributed .footer-icons {
    margin-top: 25px;
}

.footer-distributed .footer-icons a {
    display: inline-block;
    width: 35px;
    height: 35px;
    cursor: pointer;
    border-radius: 2px;

    font-size: 20px;
    color: #ffffff;
    text-align: center;
    line-height: 35px;

    margin-right: 3px;
    margin-bottom: 5px;
}
.privacy{
    justify-content:start ;
}
.p-content{
    font-size: 18px!important;
}
.p-content:hover{
    cursor: pointer;
}
 
.mada-logo{
    height: 60px;
    width: 180px;
}

@media (max-width: 880px) {

    .footer-distributed {
        font: bold 14px sans-serif;
    }

    .footer-distributed .footer-left,
    .footer-distributed .footer-center,
    .footer-distributed .footer-right {
        display: block;
        width: 100%;
        margin-bottom: 40px;
        text-align: center;
    }

    .footer-distributed .footer-center .icon {
        margin-left: 0;
    }
    .privacy{
        justify-content:center ;
    }

}

/* ------------ */


.sky {
    height: 30vh;
    width: 100%;
    background-image: url('https://i.imgur.com/gNXpUHL.jpg');
    background-size: cover;
    background-position: center;
    position: relative;
    overflow: hidden;
}

.highway {
    height: 200px;
    width: 1000%;
    display: block;
    background-image: url('https://i.imgur.com/bVXQ8P5.jpg');
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    background-repeat: repeat;
    animation: highway 5s linear infinite;
}

@keyframes highway {
    100% {
        transform: translateX(-3000px);
    }
}

.city {
    height: 250px;
    width: 500%;
    background-image: url('https://i.imgur.com/Q6bTT2k.png');
    position: absolute;
    left: 0;
    right: 0;
    bottom: 200px;
    display: block;
    z-index: 1;
    background-repeat: repeat;
    animation: city 20s linear infinite;
}

@keyframes city {
    100% {
        transform: translateX(-1500px);
    }
}

.car {
    width: 400px;
    left: 50%;
    bottom: 100px;
    transform: translateX(-50%);
    position: absolute;
    z-index: 2;
}

.car img {
    width: 100%;
    animation: car 1s linear infinite;
}

@keyframes car {
    0% {
        transform: translateY(-4px);
    }

    50% {
        transform: translateY(0px);
    }

    100% {
        transform: translateY(-4px);
    }
}

.wheel {
    left: 50%;
    transform: translateX(-50%);
    position: absolute;
    bottom: 185px;
    z-index: 2;
}

.wheel img {
    width: 72px;
    height: 72px;
    animation: wheel 1s linear infinite;
}

@keyframes wheel {
    100% {
        transform: rotate(360deg);
    }
}

.frontwheel {
    position: absolute;
    left: 80px;
}

.backwheel {
    position: absolute;
    left: -165px;
}

.footer-company-name-copyright {
    width: 111px;
    float: left;
    font-size: 15px;
    overflow: hidden;
}

/* ======================================== */