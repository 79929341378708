.btn-up{
    position: fixed;
    bottom: 15px;
    right: 15px; 
    border: black;
    border-radius: 100%; 
    background-color: whitesmoke;
    background-color: transparent;
}
.button-up{
    color: lightseagreen;
}