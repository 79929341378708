.contact-svg {
    width: 90%;
    margin-bottom: 20px;
}

.form-body {
    align-items: center; 
    display: flex;
    justify-content: center; 
}

.form { 
    /* min-width: 40rem; */
    min-height: 25rem;
    border-radius: 20px;
    box-sizing: border-box;
    /* padding: 20px; */
}

.input-container {
    height: 50px;
    position: relative;
    width: 100%;
}

.input {
    background-color: #c4c5cc;
    border-radius: 12px;
    border: 0;
    box-sizing: border-box;
    color: #65657b;
    font-size: 18px;
    height: 100%;
    outline: 0;
    padding: 4px 20px 0;
    width: 100%;
    resize: none;
}

.cut {
    background-color: transparent;
    border-radius: 10px;
    height: 20px;
    left: 20px;
    position: absolute;
    top: -20px;
    transform: translateY(0);
    transition: transform 200ms;
    width: 76px;
}

.cut-short {
    width: 50px;
}

.input:focus~.cut,
.input:not(:placeholder-shown)~.cut {
    transform: translateY(8px);
}

.placeholderss { 
    font-family: sans-serif;
    left: 20px;
    line-height: 14px;
    pointer-events: none;
    position: absolute;
    transform-origin: 0 50%;
    transition: transform 200ms, color 200ms;
    top: 15px;
}

.input:focus~.placeholderss,
.input:not(:placeholder-shown)~.placeholderss {
    transform: translateY(-30px) translateX(10px) scale(0.75);
}

.input:not(:placeholder-shown)~.placeholderss {
    color: #808097;
}

.input:focus~.placeholderss {
    color: #dc2f55;
}

.submit {
    background-color: #08d;
    border-radius: 12px;
    border: 0;
    box-sizing: border-box;
    color: #eee;
    cursor: pointer;
    font-size: 18px;
    height: 50px;
    margin-top: 38px;
    text-align: center;
    width: 100%;
}

.submit:active {
    background-color: #06b;
}