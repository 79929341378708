.bg-highlight{
    background-color: #004f44;
}
.driver-bg{
    background-color: transparent;
    color: #fff;
}
.driver-para{
    font-size: 12px;
}
.head-driver{
    color: lightseagreen;
}