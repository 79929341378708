ul>li{
    list-style: decimal;
    text-align: justify;
    line-height: 30px;
    font-size: 14px;
}
.term > p {
    font-size: 15px;
}
.term > h4 {
    font-weight: 900;
    text-transform:capitalize  ;
}
.tick-term{
    background-color: #0f6e6a;
    border-radius: 100%;
    color: white;
    height: 30px;
    width: 30px;
}
.privacy{
    justify-content:start ;
}
.p-content{
    font-size: 14px;
}
.p-content:hover{
    cursor: pointer;
}
@media (max-width: 880px){
    .privacy{
        justify-content:center ;
    }
   
  }