.privacy  .privacy-para li {
    list-style-type: disc !important;
}
.privacy-para{
    font-size: 14px;
}
.privacy   h4{
    font-weight: 900;
    text-transform: capitalize;
}
.head-policy{
    border-bottom: solid 5px #117964;
    width: 50px;
}
.bg-footer {
    background-color: #0f6e6a;
    background-color: #004f44 !important;
  }
  @media (max-width: 880px){
    .privacy{
        justify-content:center ;
    }
   
  }
  .privacy{
    justify-content:start ;
}
.p-content{
    font-size: 14px;
}
.p-content:hover{
    cursor: pointer;
}